import styled, { css } from 'styled-components'
import { type HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

interface StyledProps extends HTMLAttributes<HTMLDivElement> {
  $menu?: boolean
  $isSub?: boolean
  $mobile?: boolean
  $isOpen?: boolean
}
export const Divider = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.linkwater};
  margin: 8px 0;
`
export const Title = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  ${props => props.theme.typography.p1};
  ${props => props.$isSub
    ? `
    ${props.theme.typography.p2};
    padding: 6px 24px;
  `
    : ''};
  ${props => props.$menu
    ? `
    &::after {
      content: " ";
      border: solid ${props.theme.color.black};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-top: -3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: all 0.2s;
      @media screen and (max-width: ${props.theme.resolution.lg}) {
        display: ${props.$isSub ? 'inline-block' : 'none'};
      } 
    }
  `
    : ''};
`
export const TitleLink = styled.a<StyledProps>`
  ${props => props.$isSub ? props.theme.typography.p2 : props.theme.typography.p1};
  text-decoration: none;
  color: ${props => props.theme.color.black};
`
export const LangItem = styled.div`
  padding: 6px 24px;
  button {
    ${props => props.theme.typography.p2};
  }
`
const linkStyles = css`
  ${props => props.theme.typography.p2};
  text-decoration: none;
  color: ${props => props.theme.color.black};
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: pre-wrap;
  padding: 6px 24px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${props => props.theme.color.lavender};
  }
  @media screen and (max-width: ${props => props.theme.resolution.lg}) {
    &:hover {
      background-color: transparent;
    }
  }
`
export const InnerLink = styled(Link)`
  ${linkStyles}
`
export const ExternalLink = styled.a`
  ${linkStyles}
`
export const Menu = styled.ul<StyledProps>`
  ${props => props.theme.typography.p2};
  color: ${props => props.theme.color.black};
  display: none;
  list-style-type: none;
  min-width: 220px;
  width: max-content;
  padding: ${props => props.$isSub ? '14px 0' : '16px 0'};
  border-radius: ${props => props.$isSub ? '0' : '16px'};
  margin: 0;
  position: ${props => props.$isSub ? 'static' : 'absolute'};
  right: -16px;
  background-color: ${props => props.$isSub ? props.theme.color.white : props.theme.color.whitesmoke};
  box-shadow: ${props => props.$isSub ? 'none' : '6px 4px 24px rgba(35, 32, 67, 0.08)'};
  transition: all 0.2s;
  @media screen and (max-width: ${props => props.theme.resolution.lg}) {
    li {
      width: 100%;
    }
  }
`
export const Wrap = styled.div<StyledProps>`${props => `
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.hover {
    & > .title::after {
      margin-bottom: -5px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    & > .menu {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: ${props.theme.resolution.lg}) {
        width: calc(100vW - 85px);
        position: absolute;
        right: -24px;
        top: 40px;
        border-radius: 0 0 16px 16px;
      }
      @media screen and (max-width: ${props.theme.resolution.sm}) {
        width: calc(100vW - 16px);
        right: -13px;
        top: 36px;
      }
      ${props.$isSub
    ? `@media screen and (max-width: ${props.theme.resolution.lg}) {
            position: static;
            box-shadow: none;
          }`
    : ''}
    }
  }
`}`
